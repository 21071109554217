import React from 'react';
import './TermsOfService.css';
import { Helmet } from 'react-helmet';

const TermsOfService = () => (
    <div className="tos-container">
        <Helmet>
            <title>Terms Of Service</title>
            <meta name="description" content="The terms of service govern your access and use of this Zero2AI, Inc/Appstorm.ai website, located at Zero2AI.io. Please read carefully before proceeding further." />
        </Helmet>
        <h1>Terms of Service</h1>
        <h3>Website Terms of Service</h3>
        <p>Last Updated: July 27, 2023</p>


        <h4>Introduction, Overview, and Your Agreement</h4>
        <p>These terms of service (and any other terms or information incorporated into them by reference)
            (collectively the “Terms”) govern your access and use of this Zero2AI, Inc/appstorm (“Zero2AI,” we,” or
            “us”) website, located at https://Zero2AI.io/ (the “Site”). These Terms do not alter in any way the terms
            or conditions of any other agreement you may have with Zero2AI for products, services, or otherwise.</p>

        <p>PLEASE READ THESE TERMS CAREFULLY AND IN THEIR ENTIRETY BEFORE USING THE SITE, AS THEY
            CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS,
            INCLUDING AN AGREEMENT TO SUBMIT (WITH LIMITED EXCEPTION) ALL CLAIMS THAT YOU MAY HAVE
            AGAINST US TO BINDING AND FINAL ARBITRATION, YOUR USE OF THE SITE AND OUR COPYRIGHT AND
            OTHER INTELLECTUAL PROPERTY RIGHTS.</p>

        <p>By accessing or using the Site, you signify that you: (1) have the legal capacity to comply with and agree
            to comply with these Terms; and (2) are not a minor in the jurisdiction in which you reside. If you do not
            meet (1) or (2), you are not authorized to access or use the Site.</p>


        <h4>Your Information</h4>
        <p>For information concerning how and why we might collect, store, use, and/or share your personal
            information when you visit the Site, please read and review our Privacy Policy.</p>

        <h4>Your Rights to Use the Site; Our Content and Intellectual Property Rights</h4>

        <p>Subject to these Terms, Zero2AI grants you a limited, non-exclusive, revocable, and personal license to
            access and use the Site solely for noncommercial and informational purposes.</p>

        <p>Unless otherwise expressly indicated by Zero2AI, all content displayed or made available on the Site,
            including without limitation, text, images, illustrations, designs, logos, domain names, service marks,
            software, scripts, and the selection, compilation and arrangement of any of the foregoing is owned by
            Zero2AI, its affiliates, licensors and/or other third parties (“Site Content”). The Site and all Site Content
            are protected by copyright, trade dress, trademark, moral rights, and other intellectual property laws in
            the United States and other international jurisdictions. All such rights are reserved.</p>

        <p>All registered and unregistered trademarks, logos, and service marks are the property of Zero2AI and/or
            their respective owners. Nothing displayed or accessed in connection with the Site shall be construed as
            granting by implication, estoppel, or otherwise, any license or right to use any trademark, logo, or
            service mark displayed in connection with the Site without the owner’s prior written permission, except
            as otherwise described herein.</p>


        <h4>Prohibited Uses</h4>
        <p>You are fully responsible for your activities while using the Site, including any content, information or
            other materials you post or upload to the Site, and you bear all risks associated with the use of the Site.
            By agreeing to these Terms, you agree to comply with all applicable federal, state, and local laws and
            regulations in connection with your use of the Site. You also agree not to use the Site to engage in any
            prohibited conduct or to assist any other person or entity in engaging in any prohibited conduct.</p>

        <p>We reserve the right (but not the obligation) in our sole discretion to (1) monitor the Site for violations of
            these Terms; (2) take appropriate legal action against anyone who uses or accesses the Site in a manner
            that we believe violates the law or these Terms, including without limitation, reporting such user to law
            enforcement authorities; (3) deny access to the Site or any features of the Site to anyone who violates
            these Terms or who we believe interferes with the ability of others to enjoy our Site or infringes the
            rights of others; and (4) otherwise manage the Site in a manner designed to protect our rights and
            property and to facilitate the proper functioning of the Site.</p>

        <p>You are prohibited from using the Site for the commission of harmful or illegal activities. Accordingly, you
            may not, or assist any other person to:</p>

        <ul>
            <li>Violate these Terms or other policies and terms posted on, or otherwise applicable to, the Site;</li>

            <li>Include sensitive personal information (such as phone numbers, residential addresses, health
                information, social security numbers, driver’s license numbers, or other account numbers) about
                yourself or any other person in any webform on the Site;</li>

            <li>Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or
                other code;</li>

            <li>Upload any material, program, or software that contains any virus, worm, spyware, Trojan horse
                or other program or code designed to interrupt, destroy or limit the functionality of the Site,
                launch a denial of service attack, or in any other way attempt to interfere with the functioning
                and availability of the Site;</li>

            <li>Except as may be the result of standard search engine or Internet browser usage, use, launch,
                develop, or distribute any automated system, including, without limitation, any spider, robot,
                cheat utility, scraper, offline reader, or any data mining or similar data gathering extraction tools
                to access the Site, or use or launch any unauthorized script or other software;</li>

            <li>Interfere with, disable, vandalize or disrupt the Site or servers or networks connected to the Site;</li>

            <li>Hack into, penetrate, disable, or otherwise circumvent the security measures of the Site or
                servers or networks connected to the Site;</li>

            <li>Impersonate another person or falsely represent an affiliation with any organization or
                institution;</li>

            <li>Send email to the addresses linked or made available on the Site (including in these Terms) to
                harass, annoy, intimidate, or threaten any of our employees or agents;
                • Use the Site in any way that violates any applicable national, federal, state, local or international
                law or regulation; or</li>

            <li>Attempt to do any of the above.</li>
        </ul>


        <h4>DMCA Copyright Infringement Notice</h4>
        <p>We have implemented the procedures described in the Digital Millennium Copyright Act of 1998
            (“DMCA”), 17 U.S.C. § 512, regarding the reporting of alleged copyright infringement and the removal of
            or disabling access to the infringing material. If you have a good faith belief that copyrighted material on
            the Site is being used in a way that infringes the copyright over which you are authorized to act, you may
            make a Notice of Infringing Material.</p>

        <p>Before serving a Notice of Infringing Material, you may wish to contact a lawyer to better understand
            your rights and obligations under the DMCA and other applicable laws. For example, if your Notice fails
            to comply with all requirements of sections 512(c)(3), your Notice may not be effective.</p>


        <h4>Termination of Repeat Infringers</h4>
        <p>We will terminate or disable your use of the Site in appropriate circumstances if you are deemed by us to
            be a repeat copyright infringer.</p>

        <h4>Notices must be sent to:</h4>

        <p>DMCA Agent: dmca@zero2ai.io</p>

        <p>Zero2AI [ contact website where notice can be sent and we will see it ]</p>

        <p>Address: 980 Broadway #142, Thornwood, NY 10594</p>

        <p>Email: dmca@zero2ai.io (with a copy to: legal@zero2ai.io)</p>


        <h4>Disclaimer</h4>
        <p>THE SITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT TO THE EXTENT PROHIBITED BY
            LAW, WE MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE) WITH RESPECT TO
            THE SITE OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND DISCLAIM ALL WARRANTIES,
            INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, AND NON-INFRINGEMENT. ZERO2AI MAKES NO WARRANTY THAT (A) THE SITE WILL
            MEET YOUR REQUIREMENTS, (B) ACCESS TO AND USE OF THE SITE WILL BE UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR-FREE, AND (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE
            WILL BE ACCURATE OR RELIABLE.</p>

        <p>Zero2AI reserves the right in our sole discretion to modify or discontinue, temporarily or permanently,
            the Site (or any part thereof) with or without notice. You agree that Zero2AI will not be liable to you or
            to any third party for any modification or discontinuance of the Site, except as set forth in the “Limitation
            of Liability” section below.</p>

        <p>You understand that we are not responsible for any activities or legal consequences of your use of the
            Site. Users are responsible for using the Site in compliance with all applicable laws and regulations of the
            jurisdictions in which such users are domiciled, reside, or are located at the time of such access or use,
            as well as these Terms. Any violation of these Terms may result in the suspension or termination by us,
            in our sole discretion, of your access to and use of the Site.</p>


        <h4>Limitation of Liability</h4>
        <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT NEITHER ZERO2AI NOR ITS OFFICERS, EMPLOYEES,
            DIRECTORS, SHAREHOLDERS, LICENSORS, SERVICE PROVIDERS, AND AGENTS WILL BE LIABLE FOR ANY
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY DAMAGES, OR DAMAGES FOR
            LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR
            OTHER INTANGIBLE LOSSES (EVEN IF ZERO2AI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
            RESULTING</p>

        <p>FROM: (A) THE USE OR THE INABILITY TO USE THE SITE OR ANY RELATED INFORMATION; (B) CONDUCT
            OF ANY THIRD PARTY (INCLUDING OTHER USERS) OF THE SITE; OR (C) ANY OTHER MATTER RELATING TO
            THE SITE. IN NO EVENT WILL ZERO2AI’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES
            OF ACTION EXCEED ONE HUNDRED DOLLARS ($100). IF YOU ARE MERELY DISSATISFIED WITH ANY
            PORTION OF THE SITE OR WITH THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
            THE USE OF THE SITE.</p>

        <p>YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF
            ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO THE USE OF THE SITE OR THESE TERMS
            MUST BE FILED BY YOU WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE
            FOREVER BARRED.</p>

        <p>BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
            LIMITATION OR EXCLUSION OF CERTAIN CATEGORIES OF DAMAGES, SOME OF THE ABOVE LIMITATIONS
            MAY NOT APPLY TO YOU.</p>


        <h4>Links to and From Other Websites</h4>
        <p>You may gain access to other websites via links on the Site. These Terms apply to the Site only and do not
            apply to other parties’ websites. Similarly, you may have come to the Site via a link from another
            website. The terms of use of other websites do not apply to the Site. Zero2AI assumes no responsibility
            for any terms of use or material outside of the Site accessed via any link. You are free to establish a
            hypertext link to the Site so long as the link does not state or imply any sponsorship of your website or
            service by Zero2AI or the Site. Unless expressly agreed to by us in writing, reference to any of our
            products, services, processes or other information by trade name, trademark, logo, or otherwise by you
            or any third party does not constitute or imply endorsement, sponsorship or recommendation thereof
            by us. You may not, without our prior written permission, frame or inline link any of the content of the
            Site, scrape the Site or incorporate into another website or other service any of our material, content or
            intellectual property unless you are otherwise permitted by us to do so in accordance with a license or
            subject to separate terms.</p>


        <h4>Dispute Resolution by Binding Arbitration</h4>

        <h3>PLEASE READ THIS SECTION CAREFULLY, AS IT AFFECTS YOUR RIGHTS.</h3>

        <h4>1. Agreement to Arbitrate.</h4>
        <p>This Dispute Resolution by Binding Arbitration section is referred to in these Terms as the “Arbitration
            Agreement.” You and Zero2AI agree that any and all disputes, claims, demands, or causes of action
            (“Claims”) that have arisen or may arise between you and us, whether arising out of or relating to these
            Terms, the Site, or any aspect of the relationship or transactions between us, will be resolved exclusively
            through final and binding arbitration before a neutral arbitrator, rather than in a court by a judge or jury,
            in accordance with the terms of this Arbitration Agreement, except that you or we may (but are not
            required to) assert individual Claims in small claims court if such Claims are within the scope of such
            court’s jurisdiction. Further, this Arbitration Agreement does not preclude you from bringing issues to
            the attention of federal, state, or local agencies, and such agencies can, if the law allows, seek relief
            against us on your behalf. You agree that, by entering into these Terms, you and we are each waiving the
            right to a trial by jury or to participate in a class action and that our respective rights will be determined
            by a neutral arbitrator, not a judge or jury. The Federal Arbitration Act governs the interpretation and
            enforcement of this Arbitration Agreement.</p>


        <h4>2. Prohibition of Class and Representative Actions and Non-Individualized Relief.</h4>
        <p>YOU AND WE AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
            INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            REPRESENTATIVE ACTION OR PROCEEDING.</p>


        <h4>3. Pre-Arbitration Dispute Resolution.</h4>
        <p>Before commencing any arbitration (or suit in small claims court, if available), you agree to provide
            Zero2AI with a written notice of Claim, and Zero2AI agrees to provide you with a written notice of Claim
            to the extent reasonably possible based on the availability of your contact information to Zero2AI
            (“Notice”). The Notice to Zero2AI shall be sent to legal@zero2ai.io with a paper copy to Zero2AI, Inc.,
            980 Broadway #142, Thornwood, NY 10594. Where Zero2AI has your contact information, Zero2AI will
            send its Notice to you using the last email address we have on file for you if you have provided us with
            an email address (each, a “Notice Address”). The Notice must (i) describe the nature and basis of the
            Claim in sufficient detail to evaluate the merits of the claiming party’s Claim and (ii) set forth the specific
            relief sought, including the amount of money (if any) that is demanded and the means by which the
            demanding party calculated the claimed amount. Both parties agree that they will attempt t o resolve a
            Claim through informal negotiation within sixty (60) calendar days from the date the Notice is received.
            If the Claim is not resolved within sixty (60) calendar days after the Notice is received, you or we may
            commence an arbitration proceeding. Each party agrees that state and federal courts in New York, New
            York, may enter injunctive relief to enforce the pre-filing requirements of this paragraph, including an
            injunction to stay an arbitration that has been commenced in violation of this paragraph.</p>


        <h4>4. Arbitration Procedures.</h4>
        <p>The Federal Arbitration Act fully applies to the Arbitration Agreement. The arbitration will be conducted
            by a neutral arbitrator in accordance with the American Arbitration Association’s (“AAA”) Consumer
            Arbitration Rules if you are a consumer or the Commercial Arbitration Rules if you are using the site for
            any commercial purpose which rules are in effect at the time that such arbitration is initiated
            (collectively, the “AAA Rules”), as modified by this Arbitration Agreement. Information about the AAA
            Rules and fees for disputes can be found on the AAA’s consumer arbitration page, http://www.adr.org . If
            there is any inconsistency between any term of the AAA Rules and any term of this Arbitration
            Agreement, the applicable terms of this Arbitration Agreement will control. The arbitrator must also
            follow the provisions of these Terms as a court would. Except as set forth above, all issues are for the
            arbitrator to decide, including, but not limited to, threshold issues relating to the scope, enforceability,
            and arbitrability of this Arbitration Agreement and issues relating to (a) whether the terms of these
            Terms (or any aspect thereof) are enforceable, unconscionable, or illusory and (b) any defense to
            arbitration, including waiver, delay, laches, or estoppel. During arbitration proceedings, the amount of
            any settlement offer made by Zero2AI or you shall not be disclosed to the arbitrator. Although arbitration
            proceedings are usually simpler and more streamlined than trials and other judicial proceedings, the
            arbitrator can award the same damages and relief on an individual basis that a court can award to an
            individual under these Terms and applicable law. While an arbitrator may award declaratory or injunctive
            relief, the arbitrator may do so only with respect to the individual party seeking relief and only to the
            extent necessary to provide relief warranted by the individual party’s Claim. The arbitrator’s decision
            and judgment thereon will not have a precedent or collateral estoppel effect on any other
            Claim. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for
            very limited reasons. Any arbitration hearings will take place in New York, New York, at another mutually
            agreeable location or, if both parties agree, by telephone or video conference. Whether the arbitration
            will be conducted solely on the basis of documents submitted to the arbitrator or by a hearing will be
            determined in accordance with the AAA Rules. Regardless of the manner in which the arbitration is
            conducted, the arbitrator will issue a reasoned written decision sufficient to explain the essential
            findings and conclusions on which the award is based.</p>


        <h4>5. Small Claims Court.</h4>
        <p>Subject to applicable jurisdictional requirements, either party may elect to pursue a Claim in a local small
            claims court rather than through arbitration so long as the matter remains in a small claims court and
            proceeds only on an individual basis.</p>


        <h4>6. Cost of Arbitration.</h4>
        <p>Payment of all filing, administration and arbitrator fees (collectively, the “Arbitration Fees”) will be
            governed by the AAA Rules unless otherwise provided in this Arbitration Agreement. If you are able to
            demonstrate to the arbitrator’s satisfaction that you are economically unable to pay your portion of the
            Arbitration Fees or if the arbitrator otherwise determines for any reason that you should not be required
            to pay your portion of the Arbitration Fees, we will pay your portion of such fees, subject to allocation in
            the arbitrator’s award. In addition, if you demonstrate to the arbitrator that the costs of arbitration will
            be prohibitive as compared to the costs of litigation, we will pay as much of the Arbitration Fees as the
            arbitrator deems necessary to prevent the arbitration from being cost-prohibitive. Any payment of
            attorneys’ fees will be governed by the AAA Rules.</p>


        <h4>7. Confidentiality.</h4>
        <p>Each of the parties shall maintain the strictly confidential nature of the arbitration, including all aspects
            of the arbitration proceeding and any ruling, decision, or award by the arbitrator, and shall not (without
            the prior written consent of the other party) disclose to any third party the fact, existence, content,
            award, or other results of the arbitration, except as may be necessary to enforce, enter, or challenge
            such award in a court of competent jurisdiction or as otherwise required by law.</p>


        <h4>8. Opt Out.</h4>
        <p>You may reject this Arbitration Agreement, in which case only a court may be used to resolve any
            Claim. To reject this provision, you must send us an opt-out notice (the “Opt Out”) within thirty (30)
            days after you first access the Site. The Opt-Out must be sent to the Zero2AI Notice Address. The OptOut must include your name, phone number and the email address you used to sign up and use the
            Site. This is the only way of opting out of this Arbitration Agreement. Opting out will not affect any
            other aspect of these Terms and will have no effect on any other or future agreements you may reach to
            arbitrate with us. If you choose Opt-Out or if for any reason arbitration as the sole method of dispute
            resolution cannot be enforced, all parties hereto (i) agree that any legal suit, action or proceeding arising
            out of or relating to this Agreement shall be instituted only in a Federal or state court in the City of New
            York in the State of New York, (ii) waive any objection which they may now or hereafter have to the
            laying of the venue of any such suit, action or proceeding, and (iii) irrevocably submit to the exclusive
            jurisdiction of any Federal or state court in the City of New York in the State of New York, in any such suit,
            action or proceeding, but such consent shall not constitute a general appearance or be available to any
            other person who is not a party to this Agreement. All parties hereto agree that the mailing of any
            process in any suit, action or proceeding at the addresses of the parties shown herein shall constitute
            personal service thereof.</p>


        <h4>9. Severability.</h4>
        <p>If a court or the arbitrator decides that any term or provision of this Arbitration Agreement (other than
            Paragraph 2) above titled “Prohibition of Class and Representative Actions and Non-Individualized Relief”
            is invalid or unenforceable, the parties agree to replace such term or provision with a term or provision
            that is valid and enforceable, and that comes closest to expressing the intention of the invalid or
            unenforceable term or provision, and this Arbitration Agreement will be enforceable as so modified. If a
            court or the arbitrator decides that any of the provisions of Paragraph 2 above, titled “Prohibition of
            Class and Representative Actions and Non-Individualized Relief” are invalid or unenforceable, then the
            entirety of this Arbitration Agreement will be null and void, unless such provisions are deemed to be
            invalid or unenforceable solely with respect to Claims for public injunctive relief. The remainder of these
            Terms will continue to apply.</p>


        <h4>Choice of Law</h4>
        <p>Any and all Claims shall be governed by the Federal Arbitration Act and the internal substantive laws of
            the State of New York in all respects, without regardto conflict of law principles or for the jurisdiction or
            forum in which the user is domiciled, resides or located at the time of such access or use. Except as
            provided in the Arbitration Agreement, all Claims will be brought in the federal or state courts in New
            York, New York, and you and Zero2AI each unconditionally, voluntarily, and irrevocably consent to the
            exclusive personal jurisdiction and venue of those courts.</p>

        <p>YOU HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVE ANY RIGHT YOU MAY HAVE TO A TRIAL BY
            JURY IN RESPECT OF ANY ACTION OR PROCEEDING ARISING OUT OF OR RELATING TO THESE TERMS
            THAT IS NOT SUBJECT TO ARBITRATION, AS SET FORTH ABOVE.</p>

        <p>A printed version of these Terms and any other notice given in electronic form will be admissible in any
            arbitral, judicial, or administrative proceedings based upon or relating to these Terms and your use of
            the Site to the same extent and subject to the same conditions as other business documents and records
            originally generated and maintained in printed form.</p>


        <h4>Waiver and Severability</h4>
        <p>If you do not comply with a portion of these Terms and we do not take action right away, this does not
            mean we are giving up any of our rights under these Terms. If any part of these Terms is determined to
            be invalid or unenforceable by a court of competent jurisdiction or arbitrator, the remainder of the
            Terms shall be enforced to the maximum extent permitted by law.</p>


        <h4>Entire Agreement; Construction</h4>
        <p>These Terms contain the entire agreement between you and Zero2AI regarding your use of the Site and
            supersede any prior or contemporaneous agreements, communications, or understandings between you
            and Zero2AI on that subject.</p>


        <h4>Assignment and Delegation</h4>
        <p>You may not assign or delegate these Terms or any rights or obligations under these Terms. Any
            attempted or purported attempted assignment or delegation shall be null and void and will
            automatically terminate your right to use the Site. We may assign or delegate these Terms or any rights
            or obligations under these Terms in connection with a merger, acquisition, or sale of all or substantially
            all of our assets or to any affiliate or as part of a corporate reorganization.</p>


        <h4>Notices</h4>
        <p>All notices to Zero2AI under these Terms, unless otherwise specified, shall be sent to legal@zero2ai.io.
            Service of any notice will be deemed given on the date of receipt delivered by email.</p>


        <h4>Changes to these Terms</h4>
        <p>We may change or modify these Terms by posting a revised version on the Site or by otherwise providing
            notice to you, and we will state at the top of the revised Terms the date they were last revised. Changes
            will not apply retroactively and will become effective no earlier than fourteen (14) calendar days after
            they are posted, except for changes addressing changes made for legal reasons, which will be effective
            immediately. Your continued use of the Site after any change means you agree to the new Terms</p>
    </div>
);

export default TermsOfService;
