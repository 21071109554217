import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './ArtGallery.css';
import ai_img2 from "../assets/img/ai_img2.jpeg";
import ai_img3 from "../assets/img/ai_img3.jpeg";
import ai_img4 from "../assets/img/ai_img4.jpeg";
import ai_img5 from "../assets/img/ai_img5.jpeg";
import ai_img16 from "../assets/img/ai_img16.jpeg";
import ai_img17 from "../assets/img/ai_img17.jpeg";
import ai_img18 from "../assets/img/ai_img18.jpeg";
import ai_img19 from "../assets/img/ai_img19.jpeg";
import ai_img21 from "../assets/img/ai_img21.jpeg";
import { Helmet } from 'react-helmet';

const images = [
  { src: ai_img19, alt: "AI-generated sunset" },
  { src: ai_img2, alt: "AI-generated cat portrait" },
  { src: ai_img17, alt: "AI-generated rainforest landscape with waterfall" },
  { src: ai_img3, alt: "AI-generated lizard on a branch" },
  { src: ai_img21, alt: "AI-generated symmetrical flower pattern" },
  { src: ai_img4, alt: "AI-generated graffiti on city building" },
  { src: ai_img5, alt: "AI-generated high definition astronomy" },
  { src: ai_img16, alt: "AI-generated artsy mountains" },
  { src: ai_img18, alt: "AI-generated view of strong waves hitting the shore" },
];

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 2
};

export const ArtGallery = () => {
  const appUrls = [
    'https://customer-ycdaw6va0dz4n707.cloudflarestream.com/2ad42c99f5515827eaf436f6f746540b/watch',
    'https://customer-ycdaw6va0dz4n707.cloudflarestream.com/f573c1bd42e92548788af7f57730b334/watch',
    'https://customer-ycdaw6va0dz4n707.cloudflarestream.com/1753e690d35f7cf7de23bc4387c36103/iframe?poster=https%3A%2F%2Fcustomer-ycdaw6va0dz4n707.cloudflarestream.com%2F1753e690d35f7cf7de23bc4387c36103%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600'
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const arrowStyles = {
    position: 'absolute',
    zIndex: 1000,
    top: 'calc(50% - 15px)',
    width: 40,
    height: 40,
    cursor: 'pointer',
    backgroundColor: 'gray',
    color: 'white', // Set color of arrow characters
    // boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px'  // Adjust size of arrow characters
  };


  // create arrow components
  const ArrowPrev = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{
          ...arrowStyles,
          left: windowWidth <= 500 ? 'calc(50% - 180px)' : '0px', // adjust as per requirement
        }}
      >
        &#10094;
      </button>
    );

  const ArrowNext = (onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{
          ...arrowStyles,
          right: windowWidth <= 500 ? 'calc(50% - 180px)' : '0px', // adjust as per requirement
        }}
      >
        &#10095;
      </button>
    );

  const CarouselWrapper = ({ children }) => (
    <div
      style={{
        position: 'relative',
        maxWidth: '800px',
        margin: '0 auto',
        zIndex: 2  // remove this z-index
      }}
    >
      {children}
    </div>
  );


  return (
    <div id="art-gallery">
      <Helmet>
        <title>Appstorm</title>
        <meta name="description" content="Explore the content that Appstorm can generate for your apps" />
      </Helmet>
      <h1>AI-mazing Creations</h1>
      <h2>Try out some of Appstorm's latest and greatest:</h2>
      <CarouselWrapper>
        <Carousel
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          className="mac-windows-carousel custom-carousel"
          transitionTime={750}
          renderArrowPrev={ArrowPrev}
          renderArrowNext={ArrowNext}
        >
          {appUrls.map((url, index) => (
            <div className="mac-window" key={index}>
              {/* <div className="mac-window-buttons">
                <div className="mac-window-button red"></div>
                <div className="mac-window-button yellow"></div>
                <div className="mac-window-button green"></div>
              </div> */}
              <iframe
                src={url}
                className="mac-window-content"
                style={{ margin: 0, border: 'none', width: '100%', height: '100%' }} // update this line
              ></iframe>
            </div>
          ))}
        </Carousel>
      </CarouselWrapper>
      <h3 className="gallery-intro">Explore the images that Appstorm can generate for your apps:</h3>
      {/* <p>Appstorm can help you make your own</p> */}
      <div className="masonry-container">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {
            (windowWidth <= 768 ? images.slice(0, 8) : images).map((imageObj, index) => (
              <div key={index}>
                <img src={imageObj.src} alt={imageObj.alt} />
              </div>
            ))
          }
        </Masonry>
      </div>
    </div>
  );
};

export default ArtGallery;
