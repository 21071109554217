import './Projects.css';

export const ProjectCard = ({ imgUrl, name, tweet, username }) => {
  return (
    <div className="card-col mb-4">
      <div className="testimonial-card">
        <img className="profile-picture" src={imgUrl} alt="Profile" />
        <div className="testimonial-content">
          <h4>{name}</h4>
          <span>@{username}</span>
          <p>{tweet}</p>
        </div>
      </div>
    </div>
  )
}
