import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React, { useState, useEffect } from "react";
import './Projects.css';

export const Projects = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const testimonials = [
    {
      imgUrl: "link_to_profile_pic1",
      name: "User 1",
      username: "username1",
      tweet: "This is a testimonial tweet from User 1",
    },
    {
      imgUrl: "link_to_profile_pic2",
      name: "User 2",
      username: "username2",
      tweet: "This is a testimonial tweet from User 2",
    },
    {
      imgUrl: "link_to_profile_pic3",
      name: "User 3",
      username: "username3",
      tweet: "This is a testimonial tweet from User 3",
    },
    {
      imgUrl: "link_to_profile_pic4",
      name: "User 4",
      username: "username4",
      tweet: "This is a testimonial tweet from User 4",
    },
    {
      imgUrl: "link_to_profile_pic5",
      name: "User 5",
      username: "username4",
      tweet: "This is a testimonial tweet from User 4",
    }, {
      imgUrl: "link_to_profile_pic6",
      name: "User 6",
      username: "username4",
      tweet: "This is a testimonial tweet from User 4",
    }, {
      imgUrl: "link_to_profile_pic7",
      name: "User 7",
      username: "username4",
      tweet: "This is a testimonial tweet from User 4",
    }, {
      imgUrl: "link_to_profile_pic8",
      name: "User 8",
      username: "username4",
      tweet: "This is a testimonial tweet from User 4",
    }, {
      imgUrl: "link_to_profile_pic9",
      name: "User 9",
      username: "username4",
      tweet: "This is a testimonial tweet from User 4",
    },
  ];

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <section className="project" id="projects">
      <Container>
        <div className="animate__animated animate__fadeIn">
          <h2>Our Fans</h2>
          <Tab.Container id="projects-tabs" defaultActiveKey="first">
            <Tab.Content id="slideInUp">
              <Tab.Pane eventKey="first">
                {isMobile ? (
                  <div className="card-row animate__animated animate__fadeIn">
                    {testimonials.map((testimonial, index) => {
                      return (
                        <ProjectCard
                          key={index}
                          {...testimonial}
                        />
                      )
                    })}
                  </div>
                ) : (
                  <TrackVisibility>
                    {({ isVisible }) =>
                      <div className="card-row">
                        {
                          testimonials.map((testimonial, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...testimonial}
                              />
                            )
                          })
                        }
                      </div>
                    }
                  </TrackVisibility>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </section>
  )
}