import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import DiscordPopup from "./components/DiscordPopup";
import { HowItWorks } from './components/HowItWorks';
import { Projects } from "./components/Projects";
import { ArtGallery } from "./components/ArtGallery";
import { Footer } from "./components/Footer";
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-9PG529H5S0"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-9PG529H5S0');
            `}
          </script>
        </Helmet>
        <NavBar />
        <Routes>
          <Route path="/" element={
            <>
              <Banner />
              <DiscordPopup />
              <HowItWorks />
              <ArtGallery />
              {/* <Projects /> */}
              <AboutUs />
              <Footer />
            </>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
