import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUs.css';

const AboutUs = () => (
    <section id="about-us" className="about-us">
        <Container>
            <Row>
                <Col>
                    <div className="about-us-header text-center">
                        <h1>About Us</h1>
                        <p className="tagline">Appstorm is a <span className="gradient-text"><strong>Zero2AI</strong></span> product.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={8} className="mx-auto">
                    <div className="overview">
                        <div className="mission-section">
                            <h2>Our Mission</h2>
                            <p className="message">
                                Zero2AI’s mission is to build a global AI network that enables finding, creating and
                                publishing AI apps at scale, for profit and play. We believe the best way to unleash
                                innovation is to make AI accessible to the broadest possible audience.
                            </p>
                            <p className="founded">Founded: 2020</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
);

export default AboutUs;
