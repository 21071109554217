import { Container, Row, Col, Button } from "react-bootstrap";
import appstormlogo from '../assets/img/white-orange-logo.png';
import linkedinIcon from "../assets/img/linkedin_orange.png";
// import navIcon2 from "../assets/img/zero2ai_logo.svg";
import redditIcon from '../assets/img/redditIcon_orange.png';
import discordIcon from '../assets/img/discordIcon_orange.png';
import instagramIcon from '../assets/img/instagramIcon_orange.png';
import twitterIcon from '../assets/img/twitterIcon_orange.png';
import youtubeIcon from '../assets/img/youtubeIcon_orange.png';
import facebookIcon from '../assets/img/facebookIcon_orange.png';

import './Footer.css';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="footer-row justify-content-center">
          <Col xs={12} lg={4} className="text-center mb-3">
            <img src={appstormlogo} alt="Logo" className="footerLogo" />
            <p className="footer-copyright">© 2023 Appstorm</p>
          </Col>
          <Col xs={12} lg={2} className="text-center mb-3">
            <h1>Quick Links</h1>
            <ul>
              <li><a href="https://www.zero2ai.io/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
              <li><a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              <li><a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a></li>
            </ul>
          </Col>
          <Col xs={12} lg={4} className="text-center">
            <div className="social-icons">
              <div className="icon-line">
                <a href="https://www.linkedin.com/company/appstormai/" target="_blank" rel="noopener noreferrer"><img src={linkedinIcon} alt="LinkedIn" /></a>
                <a href="https://www.reddit.com/r/AppstormAI/" target="_blank" rel="noopener noreferrer"><img src={redditIcon} alt="Reddit" /></a>
                <a href="https://www.facebook.com/appstormai/" target="_blank" rel="noopener noreferrer"><img src={facebookIcon} alt="Facebook" /></a>
                <a href="https://discord.gg/3eHhsAdTZ2" target="_blank" rel="noopener noreferrer"><img src={discordIcon} alt="Discord" /></a>
                {/* </div> */}
                <a href="https://www.instagram.com/appstormai/" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="Instagram" /></a>
                <a href="https://twitter.com/appstormai" target="_blank" rel="noopener noreferrer"><img src={twitterIcon} alt="Twitter" /></a>
                <a href="https://youtube.com/@appstormai" target="_blank" rel="noopener noreferrer"><img src={youtubeIcon} alt="YouTube" /></a>
                {/* <div className="icon-line"> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}