import React, { useState, useEffect } from 'react';
import { FaDiscord, FaTimes } from 'react-icons/fa';
import './DiscordPopup.css'; // Assuming you have a separate CSS file for the popup styles

const DiscordPopup = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 3000); // 2000ms = 2 seconds delay

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);

    if (!isVisible) return null;

    return (
        <div className="discord-popup animate-popup">
            <div className="close-btn" onClick={() => setIsVisible(false)}>
                <FaTimes color="black" />
            </div>
            <a href="https://discord.gg/3eHhsAdTZ2" target="_blank" rel="noopener noreferrer" className="discord-content">
                <FaDiscord size={30} /> {/* Adjust size as needed */}
                <span>Check out our Discord community and discover what our users have created!</span>
            </a>
        </div>
    );
};

export default DiscordPopup;
