import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io"; // import the icon
import './Banner.css';
import appstormVid from "../assets/img/new_appstorm_vid.mp4"; // Imported video file
import gradioLogo from "../assets/img/gradio.png";
import { Helmet } from 'react-helmet';

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Helmet>
        <title>Appstorm.ai: Generative AI for Effortless App Development</title>
        <meta name="description" content="Appstorm.ai lets you turn ideas into interactive mobile apps. Type in a prompt or click the tornado icon for ideas, click generate & share it with the world." />
      </Helmet>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs={12}>
            <div className="title-container">
              <h1 className="name-title">
                <span className="wrap">
                  <span className="firstword white">Build a GPT app<br /></span>
                  <span className="word gradient-text">At the Speed of Thought</span>
                </span>
              </h1>
            </div>
            <div className="subtitle-container">
              <h2 className="about-me-text">
                Shape your concepts into AI-driven experiences.
                {/* <img src={gradioLogo} alt="Gradio Logo" className="gradio-logo" /> */}
              </h2>
              <div className="video-wrapper">
                <div className="video-container">
                  <video width="100%" height="100%" autoPlay muted loop playsInline>
                    <source src={appstormVid} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <button
                className="launch-button riseAndFadeIn"
                onClick={() => { window.open('https://beta.appstorm.ai', '_blank'); }}>
                Launch Your GPT
              </button>
              {/* <button className="arrow-button riseAndFadeIn" onClick={() => { document.getElementById('next-page').scrollIntoView({ behavior: "smooth" }); }}>
                <ArrowDown size={32} />
              </button> */}
              <button className="arrow-button riseAndFadeIn" onClick={() => { document.getElementById('how-it-works').scrollIntoView({ behavior: "smooth" }); }}>
                <IoIosArrowDown size={32} />
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
