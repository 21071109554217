import React from 'react';
import './PrivacyPolicy.css';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => (
    <div className="privacypolicy-container">
        <Helmet>
            <title>Privacy Policy</title>
            <meta name="description" content="At Appstorm.ai we recognize the need to protect the privacy of your personal" />
        </Helmet>
        <h1>Privacy Policy</h1>
        <p>This Privacy Policy explains what Personal Information (defined below) we collect, how we use and share that information, and your choices concerning our data practices. This Privacy Policy is incorporated into and is part of our Terms of Service.
            Before using the Services or submitting any Personal Information to Zero2AI Inc. (Appstorm, Appstorm.ai), please review this Privacy Policy carefully. By using the Services, you agree to the practices described in this Privacy Policy. If you do not agree to this Privacy Policy, you may not access any of our Websites or otherwise use the Service.</p>

        <br /><h4>‍1. Personal Information We Collect</h4><br />
        <p>We collect information that alone or in combination with other information in our possession could be used to identify you (&ldquo;Personal Information&rdquo;) as follows:<br />
            Personal Information You Provide. We may collect Personal Information if you create an account or communicate with us as follows.<br />
            Communication Information. If you otherwise communicate with us, we may collect your name, contact information, and the contents of any messages you send (&ldquo;Communication Information&rdquo;).<br />
            Personal Information We Collect Through Our Social Media Pages. We have pages on social media sites like Instagram, Facebook, Medium, Twitter, YouTube and LinkedIn (&ldquo;Social Media Pages&rdquo;). When you interact with our Social Media Pages, we will collect Personal Information that you elect to provide to us, such as your contact details (&ldquo;Social Information&rdquo;). In addition, the companies that host our Social Media Pages may provide us with aggregate information and analytics regarding the use of our Social Media Pages, which may include information about your engagement with our social media sites.<br />
            Personal Information We Receive Automatically From Your Use of the Service. When you visit, use, and interact with the Service, we may receive certain information about your visit, use, or interactions (&ldquo;Technical Information&rdquo;). For example, we may monitor the number of people that visit the Services, peak hours of visits, which page(s) are visited, the domains our visitors come from, and which browsers people use to access the Service (e.g., Chrome, Firefox, etc.), broad geographical information, and navigation pattern. In particular, Technical Information includes the following, which is created and automatically logged in our systems:<br />
            Log data. Information that your browser automatically sends whenever you visit the Site (&ldquo;log data&rdquo;). Log data includes your Internet Protocol address, browser type and settings, the date and time of your request, and how you interacted with the Site. Cookies. Please see<br />
            the &ldquo;Cookies&rdquo; section below to learn more about how we use cookies. Device information. Includes name of the device, operating system, and browser you are using. Information collected may depend on the type of device you use and its settings. Usage Information. We collect information about how you use our Service, such as the types of content that you view<br />
            or engage with, the features you use, the actions you take, and the time, frequency, and duration of your activities.<br />
            Cookies. We use cookies to operate and administer our Site, gather usage data on our Site, and improve your experience on it. A &ldquo;cookie&rdquo; is a piece of information sent to your browser by a website you visit. Cookies can be stored on your computer for different periods of time. Some cookies expire after a certain amount of time, or upon logging out (session cookies), others survive after your browser is closed until a defined expiration date set in the cookie (as determined by the third party placing it), and help recognize your computer when you open your browser and browse the Internet again (persistent cookies). For more details on cookies please visit All About Cookies.<br />
            Analytics. We may use a variety of online analytics products (such as Google Analytics, whose data use practices are explained at www.google.com/policies/privacy/partners/) that use cookies to help us analyze how users use the Site and enhance your experience when you use the Site.<br />
            Online Tracking and Do Not Track Signals. We and our third party service providers may use cookies or other tracking technologies to collect information about your browsing activities over time and across different websites following your use of the Site. Our Site currently does not respond to &ldquo;Do Not Track&rdquo; (&ldquo;DNT&rdquo;) signals and operates as described in this Privacy Policy whether or not a DNT signal is received.<br />
            Your Choices. On most web browsers, you will find a &ldquo;help&rdquo; section on the toolbar. Please refer to this section for information on how to receive a notification when you are receiving a new cookie and how to turn cookies off.<br />
            Please note that if you limit the ability of websites to set cookies, you may be unable to access certain aspects of the Services and you may not be able to benefit from the Services&rsquo; full functionality.<br />
            Advertising networks may use cookies to collect Personal Information. Most advertising networks offer you a way to opt out of targeted advertising.<br />
            When you enter content into the Services, we may monitor what you write to check that it does not contain inappropriate content or Personal Information.</p>

        <br /><h4>2. How We Use Personal Information</h4><br />
        <p>We may use Personal Information for the following purposes:<br />
            To provide and administer access to the Services; To maintain, improve or repair any aspect of the Services, which may remain internal or may be shared with third parties if necessary; To inform you about features or aspects of the Services we believe might be of interest to you; To respond to your inquiries, comments, feedback, or questions; To send administrative<br />
            information to you, for example, information regarding the Services or changes to our terms, conditions, and policies; To analyze how you interact with our Service; To develop new programs and services; To prevent fraud, criminal activity, or misuses of our Service, and to ensure the security of our IT systems, architecture, and networks; and To comply with legal obligations and legal process and to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other third parties.<br />
            Aggregated Information. We may aggregate Personal Information and use the aggregated information to analyze the effectiveness of our Services, to improve and add features to our Services, to conduct research (which may remain internal or may be shared with third parties as necessary) and for other similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of users of our Services and share aggregated information like general user statistics with third parties, publish such aggregated information or make such aggregated information generally available. We may collect aggregated information through the Service, through cookies, and through other means described in this Privacy Policy.</p>

        <br /><h4>3. Sharing and Disclosure of Personal Information</h4><br />
        <p>In certain circumstances we may share your Personal Information with third parties without further notice to you, unless required by law, as set forth below:<br />
            Vendors and Service Providers. To assist us in meeting business operations needs and to perform certain services and functions, we may share Personal Information with vendors and service providers, including providers of hosting services, cloud services, and other information technology services providers, email communication software and email newsletter services, advertising and marketing services, and web analytics services. Pursuant to our instructions, these parties will access, process, or store Personal Information in the course of performing their duties to us.<br />
            Business Transfers. If we are involved in strategic transactions, reorganization, bankruptcy, receivership, or transition of service to another provider (collectively a &ldquo;Transaction&rdquo;), your Personal Information and other information may be shared in the diligence process with counterparties and others assisting with the Transaction and transferred to a successor or affiliate as part of that Transaction along with other assets.<br />
            Legal Requirements. If required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, including to meet national security or law enforcement requirements, (ii) protect and defend our rights or property, (iii) prevent fraud, (iv) act in urgent circumstances to protect the personal safety of a person or group, or (v) protect against legal liability.<br />
            Affiliates. We may share Personal Information with our affiliates, meaning an entity that controls, is controlled by, or is under common control with Zero2AI Inc. Our affiliates may use the Personal Information we share in a manner consistent with this Privacy Policy.<br />
            Other Users. Certain actions you take may be visible to other users of the Services or result in content that is made available to other users of the Services.<br />
            We will also share information that is required to be disclosed by law or court order.</p>

        <br /><h4>4. Update Your Information</h4><br />
        <p>If you are under 13 years old OR if you are an EU citizen or resident under 16 years old, you are not authorized to register to use the Services. Our Services are not directed to children who are under the age of 13, regardless of location, or children under 16 who are located in a European Union Member State. Zero2AI Inc. does not knowingly collect Personal Information from children under the age of 13. If you have reason to believe that a child under the age of 13, or a child under the age of 16 who is located in a European Union Member State, has provided Personal Information to us through the Services, please contact us at legal@zero2ai.io and we will take appropriate steps to investigate.</p>

        <br /><h4>5. Age Requirement</h4><br />
        <p>You can choose not to provide us with certain personal information, but this may limit your ability to use our AIML products. You can also opt-out of receiving marketing communications from us at any time.</p>

        <br /><h4>6. Links to Third Party Websites and Services</h4><br />


        <p>We may update this privacy policy from time to time. We will notify you of any material changes by posting the new policy on our website.</p>

        <p>If you have any questions about this privacy policy or our handling of your personal information, please contact us.</p>
    </div>
);

export default PrivacyPolicy;
