import { Container, Row, Col } from 'react-bootstrap';
import './HowItWorks.css';
// import main_video from '../assets/img/new_howto_appstorm_vid.mp4';
import { useInView } from 'react-intersection-observer';
import { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';

export const HowItWorks = () => {
  const videoRef = useRef();
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [inView]);

  return (
    <section className="how-it-works" id="how-it-works">
      <Helmet>
        <title>How it works</title>
        <meta name="description" content="Follow these 3 easy steps to get started!" />
      </Helmet>
      <Container>
        <Row>
          <Col>
            <div className="how-it-works-header text-center">
              <h1>How It Works</h1>
              <p>Appstorm transforms your ideas into apps in just a few easy steps.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row>
              <Col md={{ span: 8, offset: 3 }} className="how-it-works-step text-center step-box">
                <div className="step-number-wrapper">
                  <div className="step-number">1</div>
                </div>
                <h2>Let's Hear Your Idea!</h2>
                <p>Describe your app idea in simple, concise language. Our AI will take it from there.</p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 7 }} className="how-it-works-step text-center step-box step-box-offset">
                <div className="step-number-wrapper second-box-number-wrapper">
                  <div className="step-number">2</div>
                </div>
                <h2 className="second-box-title">AI Generation</h2>
                <p className="second-box-text">Appstorm's AI processes your idea and starts building your unique app.</p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 3 }} className="how-it-works-step text-center step-box step-box-offset-third">
                <div className="step-number-wrapper">
                  <div className="step-number">3</div>
                </div>
                <h2>Share Your Creation</h2>
                <p>Showcase your innovation with the world. Inspire others with your digital masterpiece.</p>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="video-col" ref={ref}>
            <div className="large-video-placeholder">
              <video ref={videoRef} controls={true} className="video-content" autoPlay muted loop playsInline>
                <source src="https://appstorm-replicate.s3.amazonaws.com/new_howto_appstorm_vid.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
