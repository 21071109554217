import { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap";
import appstormlogo from '../assets/img/white-orange-logo.png';
import { FaBars } from 'react-icons/fa';
import './NavBar.css';
import { CSSTransition } from 'react-transition-group';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  // Function to determine if the screen is a mobile screen
  const isMobileScreen = () => window.innerWidth <= 768; // Adjust 768 to your mobile breakpoint

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <img src={appstormlogo} alt="Logo" className="logo" />
        </Navbar.Brand>

        {/* Render Gallery Button next to logo on mobile */}
        {/* {isMobileScreen() && (
          <Button variant="outline-light" className="ms-2 rounded-pill nav-font-style thick-border bold-menu-item gallery-button" href="https://gallery.appstorm.ai">
            Gallery <span className="new-tag">NEW</span>
          </Button>
        )} */}

        {/* Hamburger Menu */}
        <NavDropdown title={<FaBars size={25} color="white" />} id="nav-dropdown" className="d-md-none" show={dropdownOpen} onToggle={val => setDropdownOpen(val)}>
          <CSSTransition
            in={dropdownOpen}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div>
              <NavDropdown.Item eventKey="4.1" href="#home" onClick={() => onUpdateActiveLink('home')}>Home</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2" href="#how-it-works" onClick={() => onUpdateActiveLink('how-it-works')}>How It Works</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3" href="#art-gallery" onClick={() => onUpdateActiveLink('art-gallery')}>Showcase</NavDropdown.Item>
              {/* <NavDropdown.Item eventKey="4.4" href="https://gallery.appstorm.ai">Check out the Gallery!</NavDropdown.Item> */}
            </div>
          </CSSTransition>
        </NavDropdown>

        {/* Desktop Nav Links, including Gallery button */}
        <Nav className="ms-auto d-none d-md-flex nav-align-center">
          <Nav.Link href="/#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
          <Nav.Link href="/#how-it-works" className={activeLink === 'how-it-works' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('how-it-works')}>How It Works</Nav.Link>
          <Nav.Link href="/#art-gallery" className={activeLink === 'art-gallery' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('art-gallery')}>Showcase</Nav.Link>
          {/* {!isMobileScreen() && (
            <Button variant="outline-light" className="ms-2 rounded-pill nav-font-style thick-border bold-menu-item gallery-button" href="https://gallery.appstorm.ai">
              Gallery <span className="new-tag">NEW</span>
            </Button>
          )} */}
        </Nav>
      </Container>
    </Navbar>
  )
}
